import { useRef } from 'react';
import styles from 'styles/modules/Nav.module.scss';
import inputStyles from 'styles/modules/Input.module.scss';
import Magnifier from 'components/Svg/Magnifier';
import { useDispatch } from 'react-redux';
import useOutsideClick from 'hooks/useOutsideClick';
import SearchResult from 'components/SearchResult';
import { hideOverlay, showOverlay } from 'store/ux';

export default function Search({
    onChange,
    setResultsActive,
    resultsActive,
    handleResult,
    onFocus,
    query,
    noResults,
    results,
}) {
    const dispatch = useDispatch();

    const inputRef = useRef(null);

    useOutsideClick(inputRef, () => {
        if (resultsActive) {
            setResultsActive(false);
            dispatch(hideOverlay());
        }
    });

    return (
        <div className={styles.search}>
            <div className={styles.searchIcon}>
                <Magnifier />
            </div>

            <div className={styles.searchInput}>
                <div className="inputWrapper">
                    <input
                        ref={inputRef}
                        type="text"
                        placeholder="Search..."
                        onFocus={() => {
                            onFocus();
                            dispatch(showOverlay());
                        }}
                        onBlur={
                            !resultsActive
                                ? () => dispatch(hideOverlay())
                                : undefined
                        }
                        onChange={onChange}
                        value={query}
                        className={[
                            inputStyles.input,
                            styles.searchInputElem,
                        ].join(' ')}
                    />
                </div>

                {resultsActive && (
                    <div className={styles.searchResults}>
                        {results.map((item, key) => {
                            return (
                                <SearchResult
                                    key={`SearchResult-${key}`}
                                    item={item}
                                    onClick={handleResult}
                                />
                            );
                        })}

                        {noResults && <SearchResult item={null} />}
                    </div>
                )}
            </div>
        </div>
    );
}
