import Image from 'next/image';
import { forwardRef } from 'react';
import styles from 'styles/modules/Avatar.module.scss';
import Star from './Svg/Star';
import SvgDiamond from './Svg/diamond';
import Diamond from './Svg/diamond';

const Avatar = forwardRef((props, ref) => {
    const logo = props.logo;
    const plan = props.plan;
    const name = props.name;
    const isLegend = props.isLegend;

    return (
        <div className={isLegend ? styles.avatarLegend : styles.avatar}>
            {logo?.path ? (
                <Image src={logo.path} alt={`${name} Logo`} layout="fill" />
            ) : (
                <div className={styles.profilePlaceholder}>
                    <span>{name?.slice(0, 1)}</span>
                </div>
            )}
            {plan?.type === 'premium' ? (
                <span
                    className={[
                        styles.imgTag,
                        styles[`${plan?.type}ImgTag`],
                    ].join(' ')}
                >
                    <Star />
                    Premium
                </span>
            ) : plan?.type === 'platinum' ? (
                <span
                    className={[
                        styles.imgTag,
                        styles[`${plan?.type}ImgTag`],
                    ].join(' ')}
                >
                    <SvgDiamond />
                    Platinum
                </span>
            ) : null}
            {isLegend === true && (
                <span className={styles.imgTagLegend}>
                    <svg
                        width="7"
                        height="7"
                        viewBox="0 0 7 7"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M6.01331 5.03125L5.90109 5.51797C5.87812 5.61728 5.78974 5.6875 5.6878 5.6875H1.3128C1.21087 5.6875 1.12249 5.61728 1.09952 5.51797L0.987305 5.03125H6.01331Z"
                            fill="white"
                        />
                        <path
                            d="M6.55715 2.67442L6.11418 4.59395H0.886052L0.443083 2.67442C0.423614 2.58976 0.455989 2.50139 0.52577 2.44955C0.59577 2.3977 0.689614 2.39223 0.764864 2.43533L2.11871 3.20905L3.3179 1.41026C3.35705 1.35164 3.4218 1.31533 3.49202 1.3127C3.5629 1.30964 3.62961 1.34158 3.67271 1.39714L5.07905 3.20533L6.22246 2.4432C6.29705 2.39376 6.3944 2.3942 6.46811 2.44517C6.54205 2.49614 6.57705 2.58714 6.55715 2.67442Z"
                            fill="white"
                        />
                    </svg>
                    Legend
                </span>
            )}
        </div>
    );
});

export default Avatar;
