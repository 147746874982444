import { useEffect } from 'react';
import Nav from 'components/Nav/Nav';
import Footer from 'components/Footer';
import PopupConfirm from 'components/popUps/PopupConfirm';
import { useDispatch, useSelector } from 'react-redux';
import {
    hideOverlay,
    hidePopup,
    hideSidescreen,
    setSidescreen,
    uxSelector,
} from 'store/ux';
import { authSelector } from 'store/auth';
import Script from 'next/script';

export default function AppLayout(props) {
    const dispatch = useDispatch();
    const { overlay, popup } = useSelector(uxSelector);
    const { isAuthenticated } = useSelector(authSelector);

    const handleOverlayClick = () => {
        dispatch(hideOverlay());
        dispatch(hideSidescreen());
        dispatch(hidePopup());
    };

    useEffect(() => {
        const body = document.querySelector('body');

        if (overlay.active) {
            body.classList.add('overflowHidden');
        } else {
            dispatch(setSidescreen(null));
            body.classList.remove('overflowHidden');
        }
    }, [overlay.active]);

    return (
        <div
            className={['app', overlay.active ? 'overflowHidden' : null].join(
                ' '
            )}
        >
            <Nav />
            {/* TODO: Fix transition glitch */}
            {/*<Transition location={router.pathname}>*/}
            <main>
                <div
                    className={[
                        'overlay',
                        overlay.active ? 'active' : null,
                    ].join(' ')}
                    onClick={handleOverlayClick}
                />

                <PopupConfirm {...popup} />

                {props.children}
            </main>
            {/*</Transition>*/}
            <Footer />
            <Script src="https://assets.calendly.com/assets/external/widget.js" />
        </div>
    );
}
