export default function Star(props) {
    const fill = props.fill ?? '#fff';
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 3124 2971"
        >
            <path
                fill={fill}
                d="M1562 0l513 1078 1049 57-844 764 247 1071-965-610-965 610 247-1071L0 1135l1049-57L1562 0z"
            />
        </svg>
    );
}
