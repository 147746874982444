const Book = (props) => {
    const fill = props.fill ?? '#6A6A6A';

    return (
        <svg
            width="19"
            height="16"
            viewBox="0 0 19 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.44 3C9.44 3 7.21 1 5.22 1C3.22 1 1 3 1 3V15C1 15 2.99 13 5.22 13C7.45 13 9.44 15 9.44 15M9.44 3V15M9.44 3C9.44 3 11.7 1 13.72 1C15.74 1 18 3 18 3V15C18 15 15.98 13 13.72 13C11.46 13 9.44 15 9.44 15"
                stroke={fill}
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default Book;
