const Quote = (props) => {
    const fill = props.fill ?? '#6A6A6A';

    return (
        <svg
            width="19"
            height="20"
            viewBox="0 0 19 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16.9274 5.80686C15.9895 4.35839 14.6713 3.20437 13.1153 2.46938C13.0163 2.42263 12.9249 2.3999 12.8357 2.3999C12.7151 2.3999 12.3218 2.45094 12.3218 3.10701V4.19455C12.3218 4.74585 12.6573 4.99798 12.7602 5.06213C14.7576 6.30774 15.9501 8.45138 15.9501 10.7964C15.9501 14.5198 12.922 17.5491 9.19994 17.5491C5.4779 17.5491 2.44984 14.5198 2.44984 10.7964C2.44984 8.41468 3.67121 6.25358 5.71706 5.01538C5.99114 4.84952 6.13593 4.56039 6.13593 4.17923V3.07638C6.13593 2.58874 5.82498 2.41571 5.55874 2.41571C5.45377 2.41571 5.34181 2.44157 5.24369 2.4885C3.69852 3.22778 2.39025 4.38173 1.46035 5.82567C0.504973 7.30913 0 9.02793 0 10.7964C0 15.8712 4.12712 19.9999 9.2 19.9999C14.2729 19.9999 18.4 15.8712 18.4 10.7964C18.4 9.02002 17.8908 7.29467 16.9274 5.80686Z"
                fill={fill}
            />
            <path
                d="M8.58537 7.2H9.81463C10.1374 7.2 10.4 6.92394 10.4 6.58462V0.615385C10.4 0.276062 10.1374 0 9.81463 0H8.58537C8.2626 0 8 0.276062 8 0.615385V6.58462C8 6.92394 8.26254 7.2 8.58537 7.2Z"
                fill={fill}
            />
        </svg>
    );
};

export default Quote;
