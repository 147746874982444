import { HYDRATE } from 'next-redux-wrapper';
import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getReq } from 'api';

const hydrate = createAction(HYDRATE);

export const fetchGeo = createAsyncThunk(
    'geo/fetchGeo',
    async (_, { rejectWithValue }) => {
        try {
            const res = await getReq('/addresses');
            return res.data.data;
        } catch (err) {
            let error = err;
            if (!error.response) {
                throw err;
            }
            return rejectWithValue(error.response.data);
        }
    }
);

const geoSlice = createSlice({
    name: 'geo',
    initialState: {},
    reducers: {},
    extraReducers(builder) {
        builder.addCase(hydrate, (state, action) => {
            return {
                ...state,
                ...action.payload.geo,
            };
        });
        builder.addCase(fetchGeo.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(fetchGeo.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = null;
            state.data = payload;
        });
        builder.addCase(fetchGeo.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload;
        });
    },
});

export const { _ } = geoSlice.actions;

export default geoSlice.reducer;

export const geoSelector = ({ geo }) => geo;
