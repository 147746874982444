import { HYDRATE } from 'next-redux-wrapper';

import {
    combineReducers,
    createAction,
    createAsyncThunk,
    createSlice,
    isAnyOf,
} from '@reduxjs/toolkit';
import { deleteReq, fileUpload, getReq, putReq } from 'api';

import { reset as resetAlerts } from 'store/alert';
import expertise, { reset as resetExpertise } from './expertise';
import experts, { reset as resetExpert } from './expert';
import coverages, { reset as resetCoverage } from './coverage';
import stories, { reset as resetStory } from './story';

const hydrate = createAction(HYDRATE);

// async thunks
export const fetchCompanyBySlug = createAsyncThunk(
    'company/fetchCompanyBySlug',
    async (slug, { rejectWithValue }) => {
        try {
            const res = await getReq(`/profiles/slug/${slug}`);
            return res.data.data;
        } catch (err) {
            let error = err;
            if (!error.response) {
                throw err;
            }
            return rejectWithValue(error.response.data);
        }
    }
);

export const fetchCompanyById = createAsyncThunk(
    'company/fetchCompanyById',
    async (id, { rejectWithValue }) => {
        try {
            const res = await getReq(`/profiles/${id}`);
            return res.data.data;
        } catch (err) {
            let error = err;
            if (!error.response) {
                throw err;
            }
            return rejectWithValue(error.response.data);
        }
    }
);

export const updateCompanyById = createAsyncThunk(
    'company/updateCompanyById',
    async (companyDto, { getState, rejectWithValue }) => {
        try {
            const res = await putReq(
                `/profiles/${getState().company.data.id}`,
                companyDto
            );
            return res.data.data;
        } catch (err) {
            let error = err;
            if (!error.response) {
                throw err;
            }
            return rejectWithValue(error.response.data);
        }
    }
);

export const updateAddresses = createAsyncThunk(
    'company/updateAddresses',
    async (companyDto, { getState, rejectWithValue }) => {
        try {
            const res = await putReq(
                `/profiles/${getState().company.data.id}/addresses/batchUpdate`,
                companyDto
            );
            return res.data.data;
        } catch (err) {
            let error = err;
            if (!error.response) {
                throw err;
            }
            return rejectWithValue(error.response.data);
        }
    }
);

export const addLogo = createAsyncThunk(
    'company/addLogo',
    async (logo, { getState, rejectWithValue }) => {
        try {
            const res = await fileUpload(
                `/profiles/${getState().company.data.id}/logo`,
                logo
            );
            return res.data.data;
        } catch (err) {
            let error = err;
            if (!error.response) {
                throw err;
            }
            return rejectWithValue(error.response.data);
        }
    }
);

export const addFile = createAsyncThunk(
    'company/addFile',
    async (file, { getState, rejectWithValue }) => {
        try {
            const res = await fileUpload(
                `/profiles/${getState().company.data.id}/files`,
                file
            );
            return res.data.data;
        } catch (err) {
            let error = err;
            if (!error.response) {
                throw err;
            }
            return rejectWithValue(error.response.data);
        }
    }
);

export const removeFile = createAsyncThunk(
    'company/removeFile',
    async (id, { getState, rejectWithValue }) => {
        try {
            await deleteReq(
                `/profiles/${getState().company.data.id}/files/remove/${id}`
            );
            return id;
        } catch (err) {
            let error = err;
            if (!error.response) {
                throw err;
            }
            return rejectWithValue(error.response.data);
        }
    }
);

const initialState = {};

const companySlice = createSlice({
    name: 'company',
    initialState,
    reducers: {
        reset: () => initialState,
    },
    extraReducers(builder) {
        builder
            .addCase(hydrate, (state, action) => {
                return {
                    ...state,
                    ...action.payload.company.data,
                };
            })
            .addCase(fetchCompanyBySlug.fulfilled, (state, { payload }) => {
                state = Object.assign(state, payload);
            })
            .addCase(fetchCompanyById.fulfilled, (state, { payload }) => {
                state = Object.assign(state, payload);
            })
            .addCase(updateCompanyById.fulfilled, (state, { payload }) => {
                const { logo, ...rest } = payload;
                state = Object.assign(state, rest);
            })
            .addCase(updateAddresses.fulfilled, (state, { payload }) => {
                state.addresses = payload;
            })
            .addCase(addLogo.fulfilled, (state, { payload }) => {
                state.logo = payload;
            })
            .addCase(addFile.fulfilled, (state, { payload }) => {
                state.files.push(payload);
            })
            .addCase(removeFile.fulfilled, (state, { payload }) => {
                state.files = state.files.filter((item) => item.id !== payload);
            })
            .addCase(fetchCompanyBySlug.rejected, (state, action) => {
                if (action.payload) {
                    state.error = action.payload.errorMessage;
                } else {
                    state.error = action.error.message;
                }

                // company not found so throw error to show 404
                throw action.payload;
            })
            .addCase(fetchCompanyById.rejected, (state, action) => {
                if (action.payload) {
                    state.error = action.payload.errorMessage;
                } else {
                    state.error = action.error.message;
                }
                state.loading = false;

                // company not found so throw error to show 404
                throw action.payload;
            })
            .addMatcher(
                isAnyOf(
                    fetchCompanyById.fulfilled,
                    fetchCompanyBySlug.fulfilled,
                    updateCompanyById.fulfilled,
                    updateAddresses.fulfilled,
                    addFile.fulfilled,
                    addLogo.fulfilled,
                    removeFile.fulfilled
                ),
                (state) => {
                    state.loading = false;
                    state.error = null;
                }
            )
            .addMatcher(
                isAnyOf(
                    fetchCompanyById.pending,
                    fetchCompanyBySlug.pending,
                    updateCompanyById.pending,
                    updateAddresses.pending,
                    addLogo.pending,
                    addFile.pending,
                    removeFile.pending
                ),
                (state) => {
                    state.loading = true;
                    state.error = null;
                }
            )
            .addMatcher(
                isAnyOf(fetchCompanyById.rejected, fetchCompanyBySlug.rejected),
                (state, { payload }) => {
                    state.loading = false;
                }
            )
            .addMatcher(
                isAnyOf(
                    updateCompanyById.rejected,
                    updateAddresses.rejected,
                    addLogo.rejected,
                    addFile.rejected,
                    removeFile.rejected
                ),
                (state, { payload }) => {
                    state.loading = false;
                    state.error = payload;
                }
            );
    },
});

export const { reset } = companySlice.actions;

export const companySelector = ({ company }) => company;

export const handleResetData = () => async (dispatch) => {
    await dispatch(resetExpertise());
    await dispatch(resetExpert());
    await dispatch(resetCoverage());
    await dispatch(resetStory());
    await dispatch(resetAlerts());
};

export default combineReducers({
    data: companySlice.reducer,
    expertise,
    experts,
    stories,
    coverages,
});
