export default function ButtonLoader({ stroke = 'white', width = '25px' }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 27 36"
            width={width}
        >
            <rect
                x="1"
                y="1"
                width="25"
                height="34"
                stroke="rgba(255,255,255,.5)"
                strokeWidth="2"
                fill="none"
            />
            <rect
                x="1"
                y="1"
                width="25"
                height="34"
                stroke={stroke}
                strokeWidth="2"
                fill="none"
            >
                <animate
                    attributeName="stroke-dasharray"
                    repeatCount="indefinite"
                    dur="1s"
                    keyTimes="0;0.5;1"
                    values="24 216;86.4 153.6;24 216"
                />
                <animate
                    attributeName="stroke-dashoffset"
                    repeatCount="indefinite"
                    dur="1s"
                    keyTimes="0;0.5;1"
                    values="0;-120;-240"
                />
            </rect>
        </svg>
    );
}
