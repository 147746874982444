export default function ChatEmpty(props) {
    const stroke = props.stroke ?? '#212121';
    return (
        <svg
            width="24"
            height="26"
            viewBox="0 0 24 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M3 1.5H1.5V3V16.4815V20V22.73L3.80434 21.2661L8.97464 17.9815H21H22.5V16.4815V3V1.5H21H3Z"
                stroke={stroke}
                strokeWidth="3"
            />
        </svg>
    );
}
