export default function Instagram(props) {
    const fill = props.fill ?? '#212121';
    return (
        <svg viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.666687 6.00001C0.666687 4.58552 1.22859 3.22896 2.22878 2.22877C3.22898 1.22857 4.58553 0.666672 6.00002 0.666672H22C23.4145 0.666672 24.7711 1.22857 25.7713 2.22877C26.7715 3.22896 27.3334 4.58552 27.3334 6.00001V22C27.3334 23.4145 26.7715 24.771 25.7713 25.7712C24.7711 26.7714 23.4145 27.3333 22 27.3333H6.00002C4.58553 27.3333 3.22898 26.7714 2.22878 25.7712C1.22859 24.771 0.666687 23.4145 0.666687 22V6.00001ZM6.00002 3.33334C5.29278 3.33334 4.6145 3.61429 4.1144 4.11439C3.61431 4.61448 3.33335 5.29276 3.33335 6.00001V22C3.33335 22.7073 3.61431 23.3855 4.1144 23.8856C4.6145 24.3857 5.29278 24.6667 6.00002 24.6667H22C22.7073 24.6667 23.3855 24.3857 23.8856 23.8856C24.3857 23.3855 24.6667 22.7073 24.6667 22V6.00001C24.6667 5.29276 24.3857 4.61448 23.8856 4.11439C23.3855 3.61429 22.7073 3.33334 22 3.33334H6.00002ZM14 10C12.9392 10 11.9217 10.4214 11.1716 11.1716C10.4214 11.9217 10 12.9391 10 14C10 15.0609 10.4214 16.0783 11.1716 16.8284C11.9217 17.5786 12.9392 18 14 18C15.0609 18 16.0783 17.5786 16.8284 16.8284C17.5786 16.0783 18 15.0609 18 14C18 12.9391 17.5786 11.9217 16.8284 11.1716C16.0783 10.4214 15.0609 10 14 10ZM7.33335 14C7.33335 12.2319 8.03573 10.5362 9.28598 9.28596C10.5362 8.03572 12.2319 7.33334 14 7.33334C15.7681 7.33334 17.4638 8.03572 18.7141 9.28596C19.9643 10.5362 20.6667 12.2319 20.6667 14C20.6667 15.7681 19.9643 17.4638 18.7141 18.7141C17.4638 19.9643 15.7681 20.6667 14 20.6667C12.2319 20.6667 10.5362 19.9643 9.28598 18.7141C8.03573 17.4638 7.33335 15.7681 7.33335 14ZM21.3334 8.66667C21.8638 8.66667 22.3725 8.45596 22.7476 8.08089C23.1226 7.70581 23.3334 7.1971 23.3334 6.66667C23.3334 6.13624 23.1226 5.62753 22.7476 5.25246C22.3725 4.87739 21.8638 4.66667 21.3334 4.66667C20.8029 4.66667 20.2942 4.87739 19.9191 5.25246C19.5441 5.62753 19.3334 6.13624 19.3334 6.66667C19.3334 7.1971 19.5441 7.70581 19.9191 8.08089C20.2942 8.45596 20.8029 8.66667 21.3334 8.66667Z"
                fill={fill}
            />
        </svg>
    );
}
