import ButtonLoader from './ButtonLoader';
import ArrowRight from './Svg/ArrowRight';
import ArrowLeft from './Svg/ArrowLeft';

const Button = (props) => {
    const data = {
        theme: 'primary',
        classes: [],
        loading: false,
        disabled: false,
        ...props,
    };

    const body = data.loading ? <ButtonLoader /> : data.title;
    const loadingClasses = data.loading ? 'buttonDisabled buttonLoading' : null;
    const disabledClasses = data.disabled ? 'buttonDisabled' : null;
    const stickyClass =
        data.sticky && data.sticky.position
            ? `sticky sticky${
                  data.sticky.position[0].toUpperCase() +
                  data.sticky.position.substring(1)
              }`
            : null;

    const handleClick = (e) => {
        if (!data.loading && !data.disabled && data.onClick) {
            data.onClick(e);
        } else if (data.loading || data.disabled) {
            e.preventDefault();
        }
    };

    const isFormPreviousStepButton = data.theme === 'form-previous-step';
    const isFormNextStepButton =
        data.theme === 'form-next-step' || data.theme === 'form-next-step--0';

    return (
        <button
            className={[
                'button',
                `button__${data.theme}`,
                ...data.classes,
                loadingClasses,
                disabledClasses,
                stickyClass,
            ].join(' ')}
            onClick={handleClick}
        >
            {props.isAdd && <div className={'button__plus-marker'} />}

            {isFormPreviousStepButton && (
                <div className={'button__arrow-left'}>
                    <ArrowLeft />
                </div>
            )}

            {body}

            {isFormNextStepButton && (
                <div className={'button__arrow-right'}>
                    <ArrowRight />
                </div>
            )}
        </button>
    );
};

export default Button;
