import { createSlice } from '@reduxjs/toolkit';

const uxSlice = createSlice({
    name: 'ux',
    initialState: {
        sidescreen: {
            active: false,
            content: null,
        },
        overlay: {
            active: false,
        },
        popup: {
            active: false,
            loading: false,
            onConfirm: null,
            onCancel: null,
            title: null,
            body: null,
            confirmTitle: null,
            cancelTitle: null,
        },
    },
    reducers: {
        showSidescreen: (state) => {
            state.sidescreen.active = true;
        },
        hideSidescreen: (state) => {
            state.sidescreen.active = false;
        },
        setSidescreen: (state, action) => {
            state.sidescreen.content = action.payload;
        },
        showOverlay: (state) => {
            state.overlay.active = true;
        },
        hideOverlay: (state) => {
            state.overlay.active = false;
        },
        showPopup: (state) => {
            state.popup.active = true;
        },
        hidePopup: (state) => {
            state.popup.active = false;
        },
        setPopupLoading: (state, action) => {
            state.popup.loading = action.payload;
        },
        setPopupConfig: (state, action) => {
            state.popup.onCancel = action.payload.onCancel;
            state.popup.onConfirm = action.payload.onConfirm;
            state.popup.title = action.payload.title;
            state.popup.body = action.payload.body;
            state.popup.confirmTitle = action.payload.confirmTitle;
            state.popup.cancelTitle = action.payload.cancelTitle;
        },
    },
});

export const {
    showSidescreen,
    hideSidescreen,
    setSidescreen,
    showOverlay,
    hideOverlay,
    showPopup,
    hidePopup,
    setPopupConfig,
    setPopupLoading,
} = uxSlice.actions;

export const uxSelector = ({ ux }) => ux;

export const handleShowSidescreen = (content) => async (dispatch) => {
    dispatch(showOverlay());
    dispatch(showSidescreen());
    dispatch(setSidescreen(content));
};

export const handleHideSidescreen = () => async (dispatch, getState) => {
    dispatch(hideSidescreen());
    if (getState().ux.overlay) {
        dispatch(hideOverlay());
    }
    setTimeout(() => {
        dispatch(setSidescreen(null));
    }, 500);
};

export const handleShowPopup = () => async (dispatch) => {
    dispatch(showOverlay());
    dispatch(showPopup());
};

export const handleHidePopup = () => async (dispatch) => {
    dispatch(hideOverlay());
    dispatch(hidePopup());
};

// export const

export default uxSlice.reducer;
