export default function ProfileIcon(props) {
    return (
        <svg
            className={props.className}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.273 8.545a3.273 3.273 0 1 1-6.546 0 3.273 3.273 0 0 1 6.546 0Zm-1.636 0a1.636 1.636 0 1 1-3.273 0 1.636 1.636 0 0 1 3.273 0Z"
                fill="#3E20FF"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.573 8.545a3.573 3.573 0 1 1-7.145 0 3.573 3.573 0 0 1 7.145 0Zm-2.628.945a1.336 1.336 0 1 0-1.89-1.89 1.336 1.336 0 0 0 1.89 1.89Zm1.37 1.37A3.273 3.273 0 1 0 7.686 6.23a3.273 3.273 0 0 0 4.629 4.629Zm-1.157-1.158a1.636 1.636 0 1 0-2.315-2.314 1.636 1.636 0 0 0 2.315 2.314Z"
                fill="#3E20FF"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 1a9 9 0 1 0 0 18 9 9 0 0 0 0-18Zm-7.364 9c0 1.71.584 3.284 1.561 4.534a7.351 7.351 0 0 1 5.856-2.898 7.35 7.35 0 0 1 5.803 2.83A7.363 7.363 0 1 0 2.636 10ZM10 17.364a7.333 7.333 0 0 1-4.64-1.647 5.72 5.72 0 0 1 4.693-2.444 5.72 5.72 0 0 1 4.655 2.389A7.334 7.334 0 0 1 10 17.364Z"
                fill="#3E20FF"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.637 10a7.334 7.334 0 0 0 1.56 4.535 7.352 7.352 0 0 1 5.856-2.898 7.35 7.35 0 0 1 5.803 2.829A7.364 7.364 0 1 0 2.636 10Zm13.203 3.975a7.064 7.064 0 1 0-11.633.069 7.652 7.652 0 0 1 5.846-2.707 7.65 7.65 0 0 1 5.787 2.638ZM10 17.364a7.333 7.333 0 0 1-4.64-1.646 5.721 5.721 0 0 1 4.693-2.445 5.72 5.72 0 0 1 4.655 2.389A7.334 7.334 0 0 1 10 17.364ZM.7 10a9.3 9.3 0 1 1 18.6 0A9.3 9.3 0 0 1 .7 10Zm.3 0a9 9 0 1 1 18 0 9 9 0 0 1-18 0Z"
                fill="#3E20FF"
            />
        </svg>
    );
}
