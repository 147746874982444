export default function Twitter(props) {
    const fill = props.fill ?? '#212121';
    return (
        <svg viewBox="0 0 26 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M26 2.5204C25.0714 2.91836 24.0102 3.18368 22.949 3.31633C24.0102 2.65306 24.9388 1.59184 25.3367 0.397963C24.2755 1.06123 23.2143 1.45919 21.8878 1.72449C20.9592 0.66327 19.5 0 18.0408 0C15.1224 0 12.7347 2.38776 12.7347 5.30613C12.7347 5.70408 12.7347 6.10204 12.8673 6.5C8.4898 6.23469 4.5102 4.11224 1.85714 0.928568C1.45918 1.72449 1.19387 2.65306 1.19387 3.58163C1.19387 5.43877 2.12245 7.03062 3.58163 7.95919C2.65306 7.95919 1.85714 7.69388 1.19387 7.29592C1.19387 9.81633 3.05101 12.0714 5.43877 12.4694C5.04081 12.602 4.51021 12.602 3.97959 12.602C3.58163 12.602 3.31632 12.602 2.91836 12.4694C3.58163 14.5918 5.57143 16.1837 7.95919 16.1837C6.10204 17.6429 3.84694 18.4388 1.32653 18.4388C0.928572 18.4388 0.530613 18.4388 0 18.3061C2.38776 19.7653 5.17347 20.6939 8.22449 20.6939C18.0408 20.6939 23.3469 12.602 23.3469 5.57143V4.90816C24.4082 4.5102 25.3367 3.58163 26 2.5204Z"
                fill={fill}
            />
        </svg>
    );
}
