export default function Settings(props) {
    const fill = props.fill ?? '#212121';
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 23">
            <path
                fill={fill}
                fillRule="evenodd"
                d="M9.75.25v2.534a8.946 8.946 0 00-2.322.962L5.636 1.954 2.454 5.136l1.792 1.792a8.946 8.946 0 00-.962 2.322H.75v4.5h2.534c.212.826.539 1.606.962 2.322l-1.792 1.792 3.182 3.182 1.792-1.792c.716.423 1.496.75 2.322.962v2.534h4.5v-2.534a8.948 8.948 0 002.322-.962l1.792 1.792 3.182-3.182-1.792-1.792c.423-.716.75-1.496.962-2.322h2.534v-4.5h-2.534a8.948 8.948 0 00-.962-2.322l1.792-1.792-3.182-3.182-1.792 1.792a8.946 8.946 0 00-2.322-.962V.25h-4.5zM12 14.5a3 3 0 100-6 3 3 0 000 6z"
                clipRule="evenodd"
            />
        </svg>
    );
}
