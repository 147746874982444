export default function Magnifier(props) {
    const fill = props.fill ?? '#212121';
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 324 324"
        >
            <path
                fill={fill}
                fillRule="evenodd"
                d="M214 242l83 82 27-27-82-83a133 133 0 00-13-174 133 133 0 00-189 0 133 133 0 000 189 133 133 0 00174 13zM27 135a107 107 0 00184 76 107 107 0 000-153 107 107 0 00-153 0c-20 21-31 48-31 77z"
                clipRule="evenodd"
            />
        </svg>
    );
}
