import {
    createAction,
    createAsyncThunk,
    createSlice,
    isAnyOf,
} from '@reduxjs/toolkit';
import { deleteReq, getReq, postReq, putReq } from 'api';
import { HYDRATE } from 'next-redux-wrapper';

const hydrate = createAction(HYDRATE);

// async thunks
export const fetchStories = createAsyncThunk(
    'company/fetchStories',
    async (id, { getState, rejectWithValue }) => {
        try {
            const res = await getReq(
                `/profiles/${getState().company.data.id || id}/stories`
            );
            return res.data.data.items;
        } catch (err) {
            let error = err;
            if (!error.response) {
                throw err;
            }
            return rejectWithValue(error.response.data);
        }
    }
);

export const addStory = createAsyncThunk(
    'company/addStory',
    async (storyDto, { getState, rejectWithValue }) => {
        try {
            const res = await postReq(
                `/profiles/${getState().company.data.id}/stories`,
                storyDto
            );
            return res.data.data;
        } catch (err) {
            let error = err;
            if (!error.response) {
                throw err;
            }
            return rejectWithValue(error.response.data);
        }
    }
);

export const updateStory = createAsyncThunk(
    'company/updateStory',
    async (storyDto, { getState, rejectWithValue }) => {
        try {
            const { title, body, expert, status } = storyDto;
            const res = await putReq(
                `/profiles/${getState().company.data.id}/stories/${
                    storyDto.id
                }`,
                { title, body, expert, status }
            );
            return res.data.data;
        } catch (err) {
            let error = err;
            if (!error.response) {
                throw err;
            }
            return rejectWithValue(error.response.data);
        }
    }
);

export const removeStory = createAsyncThunk(
    'company/removeStory',
    async (id, { getState, rejectWithValue }) => {
        try {
            await deleteReq(
                `/profiles/${getState().company.data.id}/stories/${id}`
            );
            return id;
        } catch (err) {
            let error = err;
            if (!error.response) {
                throw err;
            }
            return rejectWithValue(error.response.data);
        }
    }
);

export const publishStoryToFeed = createAsyncThunk(
    'company/publishStoryToFeed',
    async (id, { getState, rejectWithValue }) => {
        try {
            const res = await putReq(
                `/profiles/${getState().company.data.id}/stories/${id}/publish`
            );
            return res.data.data;
        } catch (err) {
            let error = err;
            if (!error.response) {
                throw err;
            }
            return rejectWithValue(error.response.data);
        }
    }
);

export const unpublishStoryFromFeed = createAsyncThunk(
    'company/unpublishStoryFromFeed',
    async (id, { getState, rejectWithValue }) => {
        try {
            const res = await putReq(
                `/profiles/${
                    getState().company.data.id
                }/stories/${id}/unpublish`
            );
            return res.data.data;
        } catch (err) {
            let error = err;
            if (!error.response) {
                throw err;
            }
            return rejectWithValue(error.response.data);
        }
    }
);

const initialState = {
    data: null,
    loading: false,
    error: null,
};

const storiesSlice = createSlice({
    name: 'stories',
    initialState,
    reducers: {
        reset: () => initialState,
    },
    extraReducers(builder) {
        builder
            .addCase(hydrate, (state, action) => {
                return {
                    ...state,
                    ...action.payload.company.stories,
                };
            })
            .addCase(fetchStories.fulfilled, (state, { payload }) => {
                state.data = payload;
            })
            .addCase(addStory.fulfilled, (state, { payload }) => {
                if (!state.data) {
                    state.data = [payload];
                } else {
                    state.data = [payload, ...state.data];
                }
            })
            .addCase(removeStory.fulfilled, (state, { payload }) => {
                state.data = state.data.filter((item) => item.id !== payload);
            })
            .addCase(updateStory.fulfilled, (state, { payload }) => {
                const index = state.data.findIndex(
                    (item) => item.id === payload.id
                );
                state.data[index] = payload;
            })
            .addCase(publishStoryToFeed.fulfilled, (state, { payload }) => {
                const index = state.data.findIndex(
                    (item) => item.id === payload.id
                );
                state.data[index] = { ...state.data[index], ...payload };
            })
            .addCase(unpublishStoryFromFeed.fulfilled, (state, { payload }) => {
                const index = state.data.findIndex(
                    (item) => item.id === payload.id
                );
                state.data[index] = { ...state.data[index], ...payload };
            })
            .addMatcher(
                isAnyOf(
                    fetchStories.fulfilled,
                    addStory.fulfilled,
                    removeStory.fulfilled,
                    updateStory.fulfilled,
                    unpublishStoryFromFeed.fulfilled,
                    publishStoryToFeed.fulfilled
                ),
                (state) => {
                    state.loading = false;
                    state.error = null;
                }
            )
            .addMatcher(
                isAnyOf(
                    fetchStories.pending,
                    addStory.pending,
                    removeStory.pending,
                    updateStory.pending,
                    unpublishStoryFromFeed.pending,
                    publishStoryToFeed.pending
                ),
                (state) => {
                    state.loading = true;
                    state.error = null;
                }
            )
            .addMatcher(
                isAnyOf(
                    fetchStories.rejected,
                    addStory.rejected,
                    removeStory.rejected,
                    updateStory.rejected,
                    unpublishStoryFromFeed.rejected,
                    publishStoryToFeed.rejected
                ),
                (state, { payload }) => {
                    state.loading = false;
                    state.error = payload;
                }
            );
    },
});

export const { reset } = storiesSlice.actions;

export const storiesSelector = ({ company }) => company.stories;

export default storiesSlice.reducer;
