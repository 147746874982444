import Link from 'next/link';
import styles from 'styles/modules/Footer.module.scss';
import Facebook from './Svg/Facebook';
import Instagram from './Svg/Instagram';
import Twitter from './Svg/Twitter';
import { links } from 'data/footer';
import Logo from './Svg/NewspageBrandLogo';
import { useSelector } from 'react-redux';
import { authSelector } from 'store/auth';
import LinkedIn from './Svg/LinkedIn';

const Footer = () => {
    const { isAdmin, isAuthenticated } = useSelector(authSelector);

    const linksRender = links.reduce((acc, item, key) => {
        if (
            isAuthenticated === item.authenticated ||
            (item.visibleAuthenticated && item.visibleAuthenticated)
        ) {
            if (!item.admin || item.admin === isAdmin) {
                acc.push(
                    <li key={`footer-links-left-${key}`}>
                        <Link href={item.href}>
                            <a>{item.title}</a>
                        </Link>
                    </li>
                );
            }
        }
        return acc;
    }, []);
    return (
        <footer className={styles.footer}>
            <div className={['container', styles.footerContainer].join(' ')}>
                <div>
                    <ul className={styles.links}>{linksRender}</ul>
                    <div className={styles.logo}>
                        <Link href={'/'}>
                            <a>
                                <Logo color={'#2300ff'} fill={'#000'} />
                            </a>
                        </Link>
                        <span>
                            &copy; {new Date().getFullYear()} Newspage. All
                            rights reserved.
                        </span>
                    </div>
                </div>
                <div>
                    <div>
                        <ul className={styles.social}>
                            <li>
                                <a
                                    href="https://www.facebook.com/NewspageUK"
                                    target="_blank"
                                    rel="nofollow noreferrer"
                                >
                                    <Facebook fill={'#000'} />
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://twitter.com/NewspageUK"
                                    target="_blank"
                                    rel="nofollow noreferrer"
                                >
                                    <Twitter fill={'#000'} />
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.linkedin.com/company/newspageuk"
                                    target="_blank"
                                    rel="nofollow noreferrer"
                                >
                                    <LinkedIn fill={'#000'} />
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.instagram.com/newspageuk"
                                    target="_blank"
                                    rel="nofollow noreferrer"
                                >
                                    <Instagram fill={'#000'} />
                                </a>
                            </li>
                        </ul>
                        <ul className={styles.social}>
                            <li>
                                <Link href="https://newspage.co.uk/privacy-notice/">
                                    <a className={styles.privacy}>
                                        Newspage Privacy Notice
                                    </a>
                                </Link>
                            </li>
                            <li>
                                <Link href="https://newspage.co.uk/acceptable-use-policy/">
                                    <a className={styles.privacy}>
                                        Acceptable Use Policy
                                    </a>
                                </Link>
                            </li>
                            <li>
                                <Link href="https://newspage.co.uk/terms-of-use/">
                                    <a className={styles.privacy}>
                                        Terms of Use
                                    </a>
                                </Link>
                            </li>
                            <li>
                                <Link href="https://newspage.co.uk/newspage-journalist-and-blogger-terms-of-use/">
                                    <a className={styles.privacy}>
                                        Journalist and Blogger Terms of Use
                                    </a>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
