export default function ArrowLeft() {
    return (
        <svg
            width="21"
            height="13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.244 6.36l3.768 3.966-.845.906-5.226-5.5L6.163.128l.853.898-3.784 4.059 17.756-.045.003 1.275-17.747.045z"
                fill="#000"
            />
        </svg>
    );
}
