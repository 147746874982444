export default function Facebook(props) {
    const fill = props.fill ?? '#212121';

    return (
        <svg viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M26 4.31088C26 2.02072 23.9793 0 21.6891 0H4.31088C2.02072 0 0 2.02072 0 4.31088V21.6891C0 23.9793 2.02072 26 4.31088 26H13.0674V16.1658H9.8342V11.8549H13.0674V10.1036C13.0674 7.1399 15.2228 4.5803 17.9171 4.5803H21.4197V8.89118H17.9171C17.513 8.89118 17.1088 9.29534 17.1088 10.1036V11.8549H21.4197V16.1658H17.1088V26H21.6891C23.9793 26 26 23.9793 26 21.6891V4.31088Z"
                fill={fill}
            />
        </svg>
    );
}
