import { createSlice } from '@reduxjs/toolkit';

const navigationSearchSlice = createSlice({
    name: 'navigation-search',
    initialState: {
        search: null,
        toggle: false,
    },
    reducers: {
        setSearch: (state, { payload }) => {
            state.search = payload;
            state.toggle = !state.toggle;
        },
    },
});

export const navigationSearchSelector = ({ navigationSearch }) =>
    navigationSearch;

export const { setSearch } = navigationSearchSlice.actions;

export default navigationSearchSlice.reducer;
