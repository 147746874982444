export default function NewspageBrandLogo(props) {
    const fill = props.fill ?? '#212121';
    const rectFill = props.color ? props.color : fill;
    const isLegend = props.isLegend;

    if (isLegend) {
        return (
            <svg
                width="470"
                height="88"
                viewBox="0 0 470 88"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g clipPath="url(#clip0_527_10155)">
                    <path
                        d="M52.7409 72.5353H0V0H52.7409V72.5353ZM5.62322 66.8897H47.1102V5.64561H5.62322V66.8897Z"
                        fill="url(#paint0_linear_3741_3879)"
                    />
                    <path
                        d="M121.214 46.2392V72.3418H109.329V49.7146C109.329 44.4941 106.246 41.2052 101.158 41.2052C95.2155 41.2797 91.687 45.7694 91.687 51.8774V72.3418H79.8018V30.7716H91.687V38.0356C94.6138 32.8151 99.5387 30.3838 106.009 30.3018C115.279 30.3018 121.214 36.4918 121.214 46.2392Z"
                        fill="black"
                    />
                    <path
                        d="M166.862 55.1885H137.149C138.538 60.409 142.542 63.5413 147.853 63.5413C149.803 63.5406 151.733 63.1506 153.531 62.3939C155.329 61.6372 156.959 60.529 158.327 59.1337L164.567 65.4729C160.407 70.1117 154.323 72.8189 146.857 72.8189C133.605 72.8189 125.211 64.3094 125.211 51.7056C125.211 38.8706 133.917 30.3687 146.545 30.3687C161.09 30.3836 167.88 40.049 166.862 55.1885ZM155.771 48.1631C155.623 42.6741 152.057 39.1167 146.612 39.1167C141.368 39.1167 137.832 42.6741 136.904 48.1631H155.771Z"
                        fill="black"
                    />
                    <path
                        d="M209.671 72.3412L200.758 44.8887L191.881 72.3412H179.995L164.849 30.8456L176.942 30.771L186.183 60.5354L195.097 30.771H206.722L215.815 60.5354L224.899 30.771H236.606L221.534 72.3412H209.671Z"
                        fill="black"
                    />
                    <path
                        d="M268.727 34.8652L264.642 42.9793C260.095 40.4288 255.475 38.967 252.013 38.967C249.235 38.967 247.237 39.8918 247.237 42.0546C247.237 48.3192 269.106 45.1496 269.032 59.8416C269.032 68.3436 261.559 72.8332 252.169 72.8332C245.484 72.8332 238.917 70.8196 234.223 67.031L238.071 58.9914C242.208 62.2034 247.247 64.0295 252.474 64.2119C255.445 64.2119 257.711 63.2051 257.711 60.9603C257.711 54.2706 236.221 57.828 236.221 43.2553C236.221 34.6713 243.538 30.2637 252.563 30.2637C258.271 30.2693 263.866 31.862 268.727 34.8652Z"
                        fill="black"
                    />
                    <path
                        d="M315.976 52.1078C315.976 64.1746 308.117 72.602 296.663 72.602C289.427 72.602 283.879 69.2758 280.877 63.6525V87.3909H274.846V31.621H280.855V40.4287C283.827 34.7085 289.405 31.3823 296.492 31.3823C307.968 31.3823 315.976 39.8918 315.976 52.1078ZM309.892 51.9511C309.892 43.218 303.883 36.7967 295.407 36.7967C286.932 36.7967 280.855 43.1359 280.855 51.9511C280.855 60.7663 286.865 67.1205 295.407 67.1205C303.95 67.1205 309.892 60.7663 309.892 51.9511Z"
                        fill="black"
                    />
                    <path
                        d="M348.483 66.1062C345.245 70.5138 340.082 72.6766 333.627 72.6766C325.233 72.6766 319.847 67.2622 319.847 60.2294C319.847 53.1966 325.456 48.6249 335.016 48.5504H348.342V46.1564C348.342 40.1901 344.628 36.6401 337.482 36.6401C333.025 36.6401 328.858 38.348 324.624 41.2789L322.076 36.8788C327.313 33.3959 331.732 31.3823 338.641 31.3823C348.647 31.3823 354.196 36.7222 354.24 45.4553L354.329 72.341H348.476L348.483 66.1062ZM348.387 58.2978V53.4203H335.759C328.977 53.4203 325.441 55.5085 325.441 59.9982C325.441 64.4878 329.058 67.4561 334.756 67.4561C341.627 67.4188 346.901 63.9434 348.387 58.2978Z"
                        fill="black"
                    />
                    <path
                        d="M399.984 68.1124C399.984 80.0449 391.895 87.5028 379.578 87.5028C373.538 87.4436 367.686 85.3771 362.939 81.626L365.71 76.9872C369.483 80.3064 374.324 82.1446 379.341 82.163C388.203 82.163 393.974 76.756 393.974 68.2466V62.0864C391.129 67.4933 385.893 70.5884 379.036 70.5884C368.102 70.5884 360.555 62.4667 360.555 50.8697C360.555 39.5039 368.02 31.4793 378.88 31.3823C385.811 31.3823 391.048 34.4773 393.974 39.8918V31.621H399.984V68.1124ZM393.974 51.1009C393.974 42.599 388.203 36.6401 380.113 36.6401C372.024 36.6401 366.252 42.6735 366.215 51.1009C366.289 59.4538 371.987 65.4872 380.15 65.4872C388.314 65.4872 393.974 59.4538 393.974 51.1009Z"
                        fill="black"
                    />
                    <path
                        d="M445.697 54.3536H412.537C413.615 62.3186 419.394 67.3452 427.253 67.3452C429.583 67.4064 431.9 66.9842 434.061 66.1052C436.221 65.2262 438.177 63.9092 439.807 62.2366L443.112 65.794C439.265 70.127 433.567 72.603 426.77 72.603C414.825 72.603 406.587 64.1756 406.587 52.0342C406.587 39.8928 414.907 31.4654 426.688 31.4654C439.807 31.3834 446.277 40.5118 445.697 54.3536ZM440.305 49.4016C439.918 41.5932 434.681 36.6412 426.748 36.6412C419.045 36.6412 413.503 41.6678 412.575 49.4016H440.305Z"
                        fill="black"
                    />
                </g>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M457.927 13.5007C452.458 13.5007 448.026 17.9336 448.026 23.4017C448.026 28.8698 452.458 33.3026 457.927 33.3026C463.395 33.3026 467.828 28.8698 467.828 23.4017C467.828 17.9336 463.395 13.5007 457.927 13.5007ZM445.853 23.4017C445.853 16.7336 451.259 11.3281 457.927 11.3281C464.595 11.3281 470 16.7336 470 23.4017C470 30.0697 464.595 35.4753 457.927 35.4753C451.259 35.4753 445.853 30.0697 445.853 23.4017Z"
                    fill="black"
                />
                <path
                    d="M457.256 22.4087C457.939 22.4087 458.436 22.2722 458.734 22.0114C459.032 21.7631 459.193 21.341 459.193 20.7451C459.193 20.1616 459.032 19.7519 458.734 19.4912C458.436 19.2429 457.939 19.1187 457.256 19.1187H455.866V22.4087H457.256ZM455.866 24.6807V29.5225H452.563V16.7227H457.604C459.292 16.7227 460.521 17.0082 461.304 17.5793C462.098 18.138 462.483 19.0318 462.483 20.2609C462.483 21.1051 462.284 21.8004 461.875 22.3466C461.465 22.8805 460.857 23.2902 460.025 23.5385C460.484 23.6502 460.882 23.8861 461.242 24.2461C461.602 24.6062 461.962 25.1524 462.322 25.8973L464.122 29.5225H460.608L459.044 26.3443C458.734 25.7111 458.411 25.2642 458.088 25.0407C457.765 24.8048 457.331 24.6807 456.797 24.6807H455.866Z"
                    fill="black"
                />
                <defs>
                    <linearGradient
                        id="paint0_linear_3741_3879"
                        x1="-23.2599"
                        y1="-21.1327"
                        x2="41.7263"
                        y2="8.06055"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#0041C4" />
                        <stop offset="0.449806" stopColor="#0046C7" />
                        <stop offset="0.858795" stopColor="#23D6F7" />
                    </linearGradient>
                    <clipPath id="clip0_3741_3879">
                        <rect width="170" height="34" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        );
    } else {
        return (
            <svg
                width="470"
                height="88"
                viewBox="0 0 470 88"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g clipPath="url(#clip0_527_10155)">
                    <path
                        d="M52.7409 72.5353H0V0H52.7409V72.5353ZM5.62322 66.8897H47.1102V5.64561H5.62322V66.8897Z"
                        fill={rectFill}
                    />
                    <path
                        d="M121.214 46.2392V72.3418H109.329V49.7146C109.329 44.4941 106.246 41.2052 101.158 41.2052C95.2155 41.2797 91.687 45.7694 91.687 51.8774V72.3418H79.8018V30.7716H91.687V38.0356C94.6138 32.8151 99.5387 30.3838 106.009 30.3018C115.279 30.3018 121.214 36.4918 121.214 46.2392Z"
                        fill={fill}
                    />
                    <path
                        d="M166.862 55.1885H137.149C138.538 60.409 142.542 63.5413 147.853 63.5413C149.803 63.5406 151.733 63.1506 153.531 62.3939C155.329 61.6372 156.959 60.529 158.327 59.1337L164.567 65.4729C160.407 70.1117 154.323 72.8189 146.857 72.8189C133.605 72.8189 125.211 64.3094 125.211 51.7056C125.211 38.8706 133.917 30.3687 146.545 30.3687C161.09 30.3836 167.88 40.049 166.862 55.1885ZM155.771 48.1631C155.623 42.6741 152.057 39.1167 146.612 39.1167C141.368 39.1167 137.832 42.6741 136.904 48.1631H155.771Z"
                        fill={fill}
                    />
                    <path
                        d="M209.671 72.3412L200.758 44.8887L191.881 72.3412H179.995L164.849 30.8456L176.942 30.771L186.183 60.5354L195.097 30.771H206.722L215.815 60.5354L224.899 30.771H236.606L221.534 72.3412H209.671Z"
                        fill={fill}
                    />
                    <path
                        d="M268.727 34.8652L264.642 42.9793C260.095 40.4288 255.475 38.967 252.013 38.967C249.235 38.967 247.237 39.8918 247.237 42.0546C247.237 48.3192 269.106 45.1496 269.032 59.8416C269.032 68.3436 261.559 72.8332 252.169 72.8332C245.484 72.8332 238.917 70.8196 234.223 67.031L238.071 58.9914C242.208 62.2034 247.247 64.0295 252.474 64.2119C255.445 64.2119 257.711 63.2051 257.711 60.9603C257.711 54.2706 236.221 57.828 236.221 43.2553C236.221 34.6713 243.538 30.2637 252.563 30.2637C258.271 30.2693 263.866 31.862 268.727 34.8652Z"
                        fill={fill}
                    />
                    <path
                        d="M315.976 52.1078C315.976 64.1746 308.117 72.602 296.663 72.602C289.427 72.602 283.879 69.2758 280.877 63.6525V87.3909H274.846V31.621H280.855V40.4287C283.827 34.7085 289.405 31.3823 296.492 31.3823C307.968 31.3823 315.976 39.8918 315.976 52.1078ZM309.892 51.9511C309.892 43.218 303.883 36.7967 295.407 36.7967C286.932 36.7967 280.855 43.1359 280.855 51.9511C280.855 60.7663 286.865 67.1205 295.407 67.1205C303.95 67.1205 309.892 60.7663 309.892 51.9511Z"
                        fill={fill}
                    />
                    <path
                        d="M348.483 66.1062C345.245 70.5138 340.082 72.6766 333.627 72.6766C325.233 72.6766 319.847 67.2622 319.847 60.2294C319.847 53.1966 325.456 48.6249 335.016 48.5504H348.342V46.1564C348.342 40.1901 344.628 36.6401 337.482 36.6401C333.025 36.6401 328.858 38.348 324.624 41.2789L322.076 36.8788C327.313 33.3959 331.732 31.3823 338.641 31.3823C348.647 31.3823 354.196 36.7222 354.24 45.4553L354.329 72.341H348.476L348.483 66.1062ZM348.387 58.2978V53.4203H335.759C328.977 53.4203 325.441 55.5085 325.441 59.9982C325.441 64.4878 329.058 67.4561 334.756 67.4561C341.627 67.4188 346.901 63.9434 348.387 58.2978Z"
                        fill={fill}
                    />
                    <path
                        d="M399.984 68.1124C399.984 80.0449 391.895 87.5028 379.578 87.5028C373.538 87.4436 367.686 85.3771 362.939 81.626L365.71 76.9872C369.483 80.3064 374.324 82.1446 379.341 82.163C388.203 82.163 393.974 76.756 393.974 68.2466V62.0864C391.129 67.4933 385.893 70.5884 379.036 70.5884C368.102 70.5884 360.555 62.4667 360.555 50.8697C360.555 39.5039 368.02 31.4793 378.88 31.3823C385.811 31.3823 391.048 34.4773 393.974 39.8918V31.621H399.984V68.1124ZM393.974 51.1009C393.974 42.599 388.203 36.6401 380.113 36.6401C372.024 36.6401 366.252 42.6735 366.215 51.1009C366.289 59.4538 371.987 65.4872 380.15 65.4872C388.314 65.4872 393.974 59.4538 393.974 51.1009Z"
                        fill={fill}
                    />
                    <path
                        d="M445.697 54.3536H412.537C413.615 62.3186 419.394 67.3452 427.253 67.3452C429.583 67.4064 431.9 66.9842 434.061 66.1052C436.221 65.2262 438.177 63.9092 439.807 62.2366L443.112 65.794C439.265 70.127 433.567 72.603 426.77 72.603C414.825 72.603 406.587 64.1756 406.587 52.0342C406.587 39.8928 414.907 31.4654 426.688 31.4654C439.807 31.3834 446.277 40.5118 445.697 54.3536ZM440.305 49.4016C439.918 41.5932 434.681 36.6412 426.748 36.6412C419.045 36.6412 413.503 41.6678 412.575 49.4016H440.305Z"
                        fill={fill}
                    />
                </g>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M457.927 13.5007C452.458 13.5007 448.026 17.9336 448.026 23.4017C448.026 28.8698 452.458 33.3026 457.927 33.3026C463.395 33.3026 467.828 28.8698 467.828 23.4017C467.828 17.9336 463.395 13.5007 457.927 13.5007ZM445.853 23.4017C445.853 16.7336 451.259 11.3281 457.927 11.3281C464.595 11.3281 470 16.7336 470 23.4017C470 30.0697 464.595 35.4753 457.927 35.4753C451.259 35.4753 445.853 30.0697 445.853 23.4017Z"
                    fill="black"
                />
                <path
                    d="M457.256 22.4087C457.939 22.4087 458.436 22.2722 458.734 22.0114C459.032 21.7631 459.193 21.341 459.193 20.7451C459.193 20.1616 459.032 19.7519 458.734 19.4912C458.436 19.2429 457.939 19.1187 457.256 19.1187H455.866V22.4087H457.256ZM455.866 24.6807V29.5225H452.563V16.7227H457.604C459.292 16.7227 460.521 17.0082 461.304 17.5793C462.098 18.138 462.483 19.0318 462.483 20.2609C462.483 21.1051 462.284 21.8004 461.875 22.3466C461.465 22.8805 460.857 23.2902 460.025 23.5385C460.484 23.6502 460.882 23.8861 461.242 24.2461C461.602 24.6062 461.962 25.1524 462.322 25.8973L464.122 29.5225H460.608L459.044 26.3443C458.734 25.7111 458.411 25.2642 458.088 25.0407C457.765 24.8048 457.331 24.6807 456.797 24.6807H455.866Z"
                    fill="black"
                />
                <defs>
                    <clipPath id="clip0_527_10155">
                        <rect width="445.698" height="87.5256" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        );
    }
}
