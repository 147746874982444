export default function Rss(props) {
    const fill = props.fill ? props.fill : '#f44336';

    return (
        <svg
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 512 512"
        >
            <g>
                <g>
                    <g>
                        <path
                            fill={fill}
                            d="M68.611,374.851c-37.857,0-68.577,30.879-68.577,68.669c0,37.888,30.72,68.48,68.577,68.48
				c37.955,0,68.675-30.592,68.675-68.48C137.28,405.729,106.56,374.851,68.611,374.851z"
                        />
                        <path
                            fill={fill}
                            d="M4.544,175.811v89.984c64,1.183,124.063,26.691,169.441,72.289c45.44,45.471,70.943,105.759,72.131,170.081h90.24
				C333.857,326.016,186.432,178.273,4.544,175.811z"
                        />
                        <path
                            fill={fill}
                            d="M4.672,0v89.917c228.931,2.499,414.751,188.739,417.249,418.243h90.045C509.473,229.053,283.297,2.493,4.672,0z"
                        />
                    </g>
                </g>
            </g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
        </svg>
    );
}
