import { combineReducers } from 'redux';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { createWrapper } from 'next-redux-wrapper';

// reducers
import auth from 'store/auth';
import ux from 'store/ux';
import company from 'store/company';
import expertise from 'store/expertise';
import alerts from 'store/alert';
import products from 'store/product';
import navigationSearch from 'store/navigation-search';
import geo from './geo';

// root reducer which combines all the reducers to the store
const rootReducer = combineReducers({
    auth,
    company,
    expertise,
    ux,
    alerts,
    products,
    navigationSearch,
    geo,
});

// TODO: Find a better way than storing functions in redux store
const middlewareConfig = {
    serializableCheck: {
        // Ignore these action types and paths
        ignoredActions: ['ux/setPopupConfig'],
        ignoredActionPaths: [
            'payload.ux.popup.onConfirm',
            'payload.ux.popup.onCancel',
        ],
        ignoredPaths: ['ux.popup.onConfirm', 'ux.popup.onCancel'],
    },
};

// create store with redux-toolkit
// https://redux-toolkit.js.org/api/configureStore#configurestore
const makeStore = () =>
    configureStore({
        reducer: rootReducer,
        devTools: process.env.NODE_ENV !== 'production',
        middleware:
            process.env.NODE_ENV !== 'production' // eslint-disable-line
                ? getDefaultMiddleware(middlewareConfig).concat()
                : // ? getDefaultMiddleware(middlewareConfig)
                  getDefaultMiddleware(middlewareConfig),
    });

// wrapper that provides access to store in server-side
// https://github.com/kirill-konshin/next-redux-wrapper#app
export const wrapper = createWrapper(makeStore);
