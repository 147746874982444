import styles from 'styles/modules/popUps/ActionConfirm.module.scss';
import Button from 'components/Button';

const ActionConfirm = ({
    onConfirm,
    onCancel,
    title,
    body,
    confirmTitle,
    cancelTitle,
    loading,
    active,
    cancelClasses,
    confirmClasses,
}) => {
    const handleConfirm = () => {
        if (onConfirm) {
            onConfirm();
        }
    };

    const handleCancel = () => {
        if (onCancel) {
            onCancel();
        }
    };

    return (
        active && (
            <div className={styles.wrapper}>
                <div className={styles.popUp}>
                    <h4 className={styles.title}>{title || 'Are you sure?'}</h4>

                    {body && <p dangerouslySetInnerHTML={{ __html: body }} />}

                    <div className={styles.buttonWrapper}>
                        <Button
                            title={cancelTitle || 'Cancel'}
                            onClick={handleCancel}
                            classes={[
                                styles.cancelButton,
                                cancelClasses && cancelClasses,
                            ]}
                        />

                        {confirmTitle && (
                            <Button
                                title={confirmTitle || 'Confirm'}
                                onClick={handleConfirm}
                                classes={[
                                    styles.confirmButton,
                                    confirmClasses && confirmClasses,
                                ]}
                                loading={loading}
                            />
                        )}
                    </div>
                </div>
            </div>
        )
    );
};

export default ActionConfirm;
