import { HYDRATE } from 'next-redux-wrapper';
import {
    createAction,
    createAsyncThunk,
    createSlice,
    isAnyOf,
} from '@reduxjs/toolkit';

import { deleteReq, fileUpload, getReq, postReq, putReq } from 'api';
import { addNewExpertise } from 'store/expertise';

const hydrate = createAction(HYDRATE);

// async thunks
export const fetchExperts = createAsyncThunk(
    'company/fetchExperts',
    async (id, { getState, rejectWithValue }) => {
        try {
            const res = await getReq(
                `/profiles/${getState().company.data.id || id}/experts`
            );
            return res.data.data;
        } catch (err) {
            let error = err;
            if (!error.response) {
                throw err;
            }
            return rejectWithValue(error.response.data);
        }
    }
);

export const addExpert = createAsyncThunk(
    'company/addExpert',
    async (expertDto, { dispatch, getState, rejectWithValue }) => {
        try {
            const res = await postReq(
                `/profiles/${getState().company.data.id}/experts`,
                expertDto
            );

            // FILTER OUT NEWLY CREATED EXPERTISE TO ADD TO STATE
            const newExpertise = res.data.data.expertise.filter((exp) =>
                expertDto.expertise
                    .filter((item) => !item.id)
                    .some((e) => exp.name === e.name)
            );
            if (newExpertise.length > 0) {
                dispatch(addNewExpertise(newExpertise));
            }
            return res.data.data;
        } catch (err) {
            let error = err;
            if (!error.response) {
                throw err;
            }
            return rejectWithValue(error.response.data);
        }
    }
);

export const removeExpert = createAsyncThunk(
    'company/removeExpert',
    async (id, { getState, rejectWithValue }) => {
        try {
            await deleteReq(
                `/profiles/${getState().company.data.id}/experts/${id}`
            );
            return id;
        } catch (err) {
            let error = err;
            if (!error.response) {
                throw err;
            }
            return rejectWithValue(error.response.data);
        }
    }
);

export const updateExpert = createAsyncThunk(
    'company/updateExpert',
    async (expertDto, { dispatch, getState, rejectWithValue }) => {
        try {
            const res = await putReq(
                `/profiles/${getState().company.data.id}/experts/${
                    expertDto.id
                }`,
                expertDto
            );

            // FILTER OUT NEWLY CREATED EXPERTISE TO ADD TO STATE
            const newExpertise = res.data.data.expertise.filter((exp) =>
                expertDto.expertise
                    .filter((item) => !item.id)
                    .some((e) => exp.name === e.name)
            );
            if (newExpertise.length > 0) {
                dispatch(addNewExpertise(newExpertise));
            }
            return res.data.data;
        } catch (err) {
            let error = err;
            if (!error.response) {
                throw err;
            }
            return rejectWithValue(error.response.data);
        }
    }
);

export const addExpertImage = createAsyncThunk(
    'company/addExpertImage',
    async (image, { getState, rejectWithValue }) => {
        try {
            const id =
                getState().company.experts.addedExpert ||
                getState().company.experts.expert.id;
            const res = await fileUpload(
                `/profiles/${getState().company.data.id}/experts/${id}/image`,
                image
            );
            return res.data.data;
        } catch (err) {
            let error = err;
            if (!error.response) {
                throw err;
            }
            return rejectWithValue(error.response.data);
        }
    }
);

const initialState = {
    data: null,
    expert: null,
    loading: false,
    error: null,
    addedExpert: null,
};
const expertSlice = createSlice({
    name: 'experts',
    initialState,
    reducers: {
        findExpert: (state, { payload }) => {
            state.expert = payload;
        },
        resetExpertData: (state) => {
            state.expert = null;
        },
        reset: () => initialState,
    },
    extraReducers(builder) {
        builder
            .addCase(hydrate, (state, action) => {
                return {
                    ...state,
                    ...action.payload.company.experts,
                };
            })
            .addCase(fetchExperts.fulfilled, (state, { payload }) => {
                state.data = payload;
            })
            .addCase(addExpert.fulfilled, (state, { payload }) => {
                state.addedExpert = payload.id;
                state.data.push(payload);
            })
            .addCase(removeExpert.fulfilled, (state, { payload }) => {
                state.addedExpert = null;
                state.data = state.data.filter((item) => item.id !== payload);
            })
            .addCase(updateExpert.fulfilled, (state, { payload }) => {
                state.addedExpert = null;
                const index = state.data.findIndex(
                    (item) => item.id === payload.id
                );
                state.data[index] = payload;
            })
            .addCase(addExpertImage.fulfilled, (state, { payload }) => {
                const index = state.data.findIndex(
                    (item) => item.id === payload.id
                );
                state.data[index].image = payload.image;
            })
            .addMatcher(
                isAnyOf(
                    fetchExperts.fulfilled,
                    addExpert.fulfilled,
                    removeExpert.fulfilled,
                    updateExpert.fulfilled,
                    addExpertImage.fulfilled
                ),
                (state) => {
                    state.loading = false;
                    state.error = null;
                    state.expert = null;
                }
            )
            .addMatcher(
                isAnyOf(
                    fetchExperts.pending,
                    addExpert.pending,
                    removeExpert.pending,
                    updateExpert.pending,
                    addExpertImage.pending
                ),
                (state) => {
                    state.loading = true;
                    state.error = null;
                }
            )
            .addMatcher(
                isAnyOf(
                    fetchExperts.rejected,
                    addExpert.rejected,
                    removeExpert.rejected,
                    updateExpert.rejected,
                    addExpertImage.rejected
                ),
                (state, { payload }) => {
                    state.loading = false;
                    state.error = payload;
                }
            );
    },
});

export const { findExpert, resetExpertData, reset } = expertSlice.actions;

export const expertSelector = ({ company }) => company.experts;

export default expertSlice.reducer;

export const handleFindExpert = (id) => async (dispatch, getState) => {
    const expert = getState().company.experts.data.find((exp) => exp.id === id);
    dispatch(findExpert(expert));
};
