import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import links from 'data/navigation-data';
import { common, news_alerts } from 'lang/en.json';
import { authSelector, deauthenticate } from 'store/auth';
import { companySelector } from 'store/company';
import Link from 'next/link';
import Off from 'components/Svg/Off';
import { useEffect, useState } from 'react';

const NavigationLinks = ({ styles, hideNav, mobile }) => {
    const router = useRouter();
    const dispatch = useDispatch();

    const auth = useSelector(authSelector);
    const {
        data: { newNewsAlert },
        coverages: { data: coverages },
    } = useSelector(companySelector); // new NewsAlert notification
    const plan = useSelector(({ company }) => company?.data?.plan?.type);

    const [newAlert, setNewAlert] = useState(false);

    useEffect(() => {
        setNewAlert(newNewsAlert);
    }, [newNewsAlert]);

    if (!router.query.company && !auth.company) {
        return null;
    }

    // Todo: FIX THIS
    const isSomeCoverageNew = false;

    return (
        <ul className={styles.list}>
            {links.map(
                (
                    {
                        title,
                        url,
                        icon,
                        company,
                        journalist,
                        absolute,
                        tooltip,
                    },
                    key
                ) => {
                    const thisUrl = absolute
                        ? url
                        : `/${(company ? auth.company : '') + url}`;
                    const active = router.asPath === thisUrl;

                    if (
                        (company && !auth.isJournalist) ||
                        (journalist && auth.isJournalist)
                    ) {
                        return (
                            <li key={`navigation-${key}`}>
                                <Link href={thisUrl}>
                                    <a
                                        className={[
                                            styles.item,
                                            active ? styles.active : null,
                                        ].join(' ')}
                                        onClick={() =>
                                            hideNav ? hideNav() : null
                                        }
                                    >
                                        {icon(active ? '#3e20ff' : '#6A6A6A')}

                                        {/* NOTIFICATION */}
                                        {newAlert && url === '/news-alerts' && (
                                            <span
                                                className={styles.notification}
                                            >
                                                {news_alerts.new}
                                            </span>
                                        )}
                                        {/* COVERAGE NOTIFICATION */}
                                        {isSomeCoverageNew &&
                                            url === '/media-coverage' && (
                                                <span
                                                    className={
                                                        styles.notification
                                                    }
                                                />
                                            )}

                                        <span
                                            className={[
                                                styles.title,
                                                active ? styles.active : '',
                                            ].join(' ')}
                                        >
                                            {title === 'Upgrade to Premium'
                                                ? plan === 'free'
                                                    ? title
                                                    : 'Your subscription'
                                                : title}
                                        </span>
                                    </a>
                                </Link>
                                {tooltip && (
                                    <div className={[styles.tooltip]}>
                                        {tooltip}
                                    </div>
                                )}
                            </li>
                        );
                    }
                }
            )}
            {mobile && (
                <li>
                    <button
                        onClick={() => {
                            dispatch(deauthenticate());
                            hideNav ? hideNav() : null;
                        }}
                        className={styles.item}
                    >
                        <Off />
                        <span className={[styles.title].join(' ')}>
                            {common.logout}
                        </span>
                    </button>
                </li>
            )}
        </ul>
    );
};

export default NavigationLinks;
