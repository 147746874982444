const SvgDiamond = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={15}
        height={15}
        viewBox={'0 0 21 21'}
        fill="none"
        {...props}
    >
        <g clipPath="url(#a)">
            <path
                fill="#fff"
                d="M16.463 2.753a1.189 1.189 0 0 0-.806-.32H5.332c-.302 0-.587.118-.806.32L.363 6.714A1.17 1.17 0 0 0 .33 8.376l9.317 9.821c.218.235.537.37.856.37.319 0 .638-.135.856-.37l9.317-9.82c.454-.47.42-1.21-.033-1.663l-4.18-3.961Zm1.343 5.22-6.447 6.8c-.319.335-.873.1-.873-.353V5.27c0-.285.218-.503.504-.503h3.979c.134 0 .252.05.352.134l2.468 2.35c.201.202.201.521.017.723Z"
            />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M0 0h21v21H0z" />
            </clipPath>
        </defs>
    </svg>
);
export default SvgDiamond;
