import styles from 'styles/modules/SearchResult.module.scss';
import Link from 'next/link';
import { isObjectEmpty } from 'helpers/objects';

const SearchResult = ({ item, onClick }) => {
    if (!item) {
        return <div className={styles.itemNoResults}>No results found.</div>;
    }

    let expertise = {
        items: item.expertise,
        plus: 0,
    };

    if (item.expertise.length > 4) {
        expertise.items = item.expertise.slice(0, 4);
        expertise.plus = item.expertise.length - 4;
    }

    return (
        <Link href={`/${item.slug}`}>
            <a className={styles.item} onClick={onClick}>
                <div className={styles.itemRow}>
                    <div className={styles.itemLogo}>
                        {item.logo && (
                            <img src={item.logo.path} alt={item.name} />
                        )}
                    </div>
                    <div className="itemBody">
                        <div className={styles.itemTitle}>{item.name}</div>
                        {!isObjectEmpty(item.addresses[0]) && (
                            <div className={styles.itemAddress}>
                                {item.addresses[0].fullAddress}
                            </div>
                        )}
                    </div>
                </div>
                {item.expertise.length > 0 && (
                    <div className={styles.itemTags}>
                        {expertise.items.map((tag, tagKey) => {
                            return (
                                <div
                                    className={styles.itemTag}
                                    key={`ItemTag-${item.slug}-${tagKey}`}
                                >
                                    {tag.name}
                                </div>
                            );
                        })}
                        {expertise.plus > 0 && (
                            <div className={styles.itemPlus}>
                                +{expertise.plus}
                            </div>
                        )}
                    </div>
                )}
            </a>
        </Link>
    );
};

export default SearchResult;
