import { useDispatch, useSelector } from 'react-redux';
import { deauthenticate } from 'store/auth';
import { common } from 'lang/en.json';
import styles from 'styles/modules/Nav.module.scss';
import Link from 'next/link';
import Avatar from 'components/Avatar';
import { useRef, useState } from 'react';
import useOutsideClick from 'hooks/useOutsideClick';

const NavDropdown = () => {
    const { name, logo, slug, plan } = useSelector(
        ({ company }) => company.data
    );
    const [expanded, setExpanded] = useState();
    const dispatch = useDispatch();
    const profileRef = useRef(null);

    useOutsideClick(profileRef, () => {
        if (expanded) {
            setExpanded(false);
        }
    });

    return (
        <div
            className={styles.profile}
            onClick={() => setExpanded(!expanded)}
            ref={profileRef}
        >
            <Link href={`/${slug}`}>
                <Avatar
                    isLegend={plan?.type === 'legend'}
                    logo={logo}
                    name={name}
                    plan={plan}
                />
            </Link>
            <ul
                className={[
                    'border-gray',
                    styles.dropdown,
                    styles[plan?.type],
                    expanded ? styles.active : '',
                ].join(' ')}
            >
                <li onClick={() => dispatch(deauthenticate())}>
                    {common.logout}
                </li>
            </ul>
        </div>
    );
};

export default NavDropdown;
