export default function Close(props) {
    const fill = props.fill ? props.fill : '#212121';

    return (
        <svg
            width="55"
            height="55"
            viewBox="0 0 55 55"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="27.5" cy="27.5" r="27.5" fill={fill} />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M27.7807 31.7005L34.2543 38.1742L37.5805 34.848L31.1069 28.3743L37.9618 21.5194L34.6356 18.1933L27.7807 25.0481L20.7355 18.0029L17.4093 21.3291L24.4545 28.3743L17.7905 35.0383L21.1167 38.3645L27.7807 31.7005Z"
                fill="white"
            />
        </svg>
    );
}
