export default {
    title: 'Newspage - #BeTheStory',
    openGraph: {
        title: 'Newspage - #BeTheStory',
        type: 'website',
        locale: 'en_GB',
        url: process.env.HOST,
        images: [
            {
                url: `${process.env.HOST}/newspage.png`,
                alt: 'Newspage',
            },
        ],
    },
    twitter: {
        site: '@NewspageUK',
        cardType: 'summary',
    },
};
