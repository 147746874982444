import Router from 'next/router';
import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import * as Sentry from '@sentry/nextjs';

import {
    login,
    registration,
    setAuthToken,
    setRefreshTokenInterceptor,
} from 'api/index';
import parseJwt from 'utils/parse-jwt';
import { handleResetData } from './company';
import { deleteCookie, setCookie } from 'cookies-next';
import { addMonths } from 'date-fns';
import { options } from '../utils/cookie';
import {
    AUTH_TOKEN_COOKIE,
    REFRESH_TOKEN_COOKIE,
} from '../data/globalConstants';

// this is needed to persist the store from SSR to client side store
// https://github.com/kirill-konshin/next-redux-wrapper/tree/types-enchancements/packages/demo-redux-toolkit
const hydrate = createAction(HYDRATE);

// async thunks
export const registerCompany = createAsyncThunk(
    'auth/register',
    async (user, { rejectWithValue }) => {
        try {
            await registration(user);
        } catch (err) {
            let error = err;
            if (!error.response) {
                throw err;
            }
            return rejectWithValue(error.response.data);
        }
    }
);

export const authenticate = createAsyncThunk(
    'auth/login',
    async (user, { rejectWithValue, dispatch }) => {
        try {
            const res = await login(user);
            const { token, refreshToken } = res.data;
            setCookie(AUTH_TOKEN_COOKIE, token, options);
            setCookie(REFRESH_TOKEN_COOKIE, refreshToken, {
                ...options,
                expires: addMonths(new Date(), 1),
            });
            setAuthToken(token);
            setRefreshTokenInterceptor(dispatch);
            return token;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        token: null,
        isAuthenticated: false,
        isAdmin: false,
        isJournalist: false,
        isAuthorized: false,
        user: null,
        company: null,
        email: null,
        error: null,
        deactivated: false,
        loading: false,
    },
    reducers: {
        authSuccess: (state, { payload }) => {
            state.token = payload;
            state.isAuthenticated = true;
        },
        authFail: (state, action) => {
            state.token = null;
            state.isAuthenticated = false;
            state.error = action.payload;
        },
        logout: (state) => {
            state.token = null;
            state.isAuthenticated = false;
            state.isAuthorized = false;
            state.isAdmin = false;
            state.isJournalist = false;
            state.company = null;
            state.user = null;
        },
        setAuthDetails: (state, action) => {
            return { ...state, ...action.payload };
        },
        setAuthorized: (state, action) => {
            state.isAuthorized = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(hydrate, (state, action) => {
            return {
                ...state,
                ...action.payload[authSlice.name],
            };
        });
        builder.addCase(registerCompany.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(registerCompany.fulfilled, (state) => {
            state.loading = false;
            state.error = null;
        });
        builder.addCase(registerCompany.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload;
        });
        builder.addCase(authenticate.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(authenticate.fulfilled, (state, { payload }) => {
            state.token = payload;
            state.isAuthenticated = true;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(authenticate.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload.message;
        });
    },
});

export const { authSuccess, authFail, logout, setAuthorized, setAuthDetails } =
    authSlice.actions;

export const authSelector = ({ auth }) => auth;

// helper actions
// export const authenticate =
//     ({ email, password }) =>
//     async (dispatch) => {
//         try {
//             const data = await login({ email, password });
//             const { token } = data;
//             setCookie('token', token);
//             setAuthToken(token);
//             await dispatch(authSuccess({ token }));
//         } catch (err) {
//             await dispatch(authFail(err.toString()));
//         }
//     };

export const deauthenticate = () => async (dispatch) => {
    deleteCookie(AUTH_TOKEN_COOKIE, options);
    deleteCookie(REFRESH_TOKEN_COOKIE, options);
    Router.push('/login');
    Sentry.configureScope((scope) => scope.setUser(null));
    dispatch(logout());
    return dispatch(handleResetData());
};

export const handleAuthorization =
    (requestCompany) => async (dispatch, getState) => {
        const { company, email, isAdmin, isJournalist } = parseJwt(
            getState().auth.token
        );

        const req = requestCompany ? requestCompany : company;
        dispatch(setAuthDetails({ company, email, isAdmin, isJournalist }));
        const authorized = req === company || isAdmin;
        dispatch(setAuthorized(authorized));
    };

export default authSlice.reducer;
