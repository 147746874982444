const Quote = (props) => {
    const fill = props.fill ?? '#eee';

    return (
        <svg
            width="19"
            height="14"
            viewBox="0 0 19 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6.86 5.73503C7.51333 6.03836 8.015 6.49336 8.365 7.10003C8.73833 7.68336 8.925 8.3717 8.925 9.16503C8.925 10.3317 8.55167 11.2767 7.805 12C7.05833 12.7234 6.10167 13.085 4.935 13.085C3.76833 13.085 2.81167 12.7234 2.065 12C1.31833 11.2767 0.945 10.3317 0.945 9.16503C0.945 8.62836 1.015 8.0917 1.155 7.55503C1.295 7.01836 1.61 6.22503 2.1 5.17503L4.34 0.0300293H8.505L6.86 5.73503ZM16.03 5.73503C16.6833 6.03836 17.185 6.49336 17.535 7.10003C17.9083 7.68336 18.095 8.3717 18.095 9.16503C18.095 10.3317 17.7217 11.2767 16.975 12C16.2283 12.7234 15.2717 13.085 14.105 13.085C12.9383 13.085 11.9817 12.7234 11.235 12C10.4883 11.2767 10.115 10.3317 10.115 9.16503C10.115 8.62836 10.185 8.0917 10.325 7.55503C10.465 7.01836 10.78 6.22503 11.27 5.17503L13.51 0.0300293H17.675L16.03 5.73503Z"
                fill={fill}
            />
        </svg>
    );
};

export default Quote;
