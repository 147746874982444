export default function World(props) {
    const fill = props.fill ?? '#212121';

    return (
        <svg viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 13h5A29 29 0 017.5 1.6 14 14 0 000 13zM.1 16a14 14 0 007.4 10.4A28.3 28.3 0 015 16H0zM16 27.9a14.1 14.1 0 01-4 0c-.6-.6-1.1-1.4-1.7-2.5-1.2-2.4-2-5.6-2.2-9.4h11.8c-.2 3.8-1 7-2.2 9.4-.6 1.1-1.1 1.9-1.7 2.5zM20.5 26.4A14 14 0 0028 16h-5c-.2 4-1 7.6-2.4 10.4zM28 13a14 14 0 00-7.5-11.4C22 4.6 23 8.6 23 13h5zM12 .1a14.1 14.1 0 014 0c.6.6 1.1 1.4 1.7 2.5C19 5.2 19.9 8.8 20 13H8c.1-4.2 1-7.8 2.3-10.4C11 1.5 11.4.7 12 .1z"
                fill={fill}
            />
        </svg>
    );
}
