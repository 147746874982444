export default function NewspageLogo(props) {
    const fill = props.fill ?? '#212121';

    return (
        <svg
            width="16"
            height="21"
            viewBox="0 0 16 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="1"
                y="1.5"
                width="14"
                height="18"
                stroke={fill}
                strokeWidth="2"
            />
        </svg>
    );
}
